<template>
    <workspace-wrapper class="container">
        <template #workspace>
            <MiniRouletteWorkspace :bets="stateData.roundResult.bets" :resultNumber="stateData.roundResult.resultNumber"
                :denom="stateData.denom" />
        </template>
    </workspace-wrapper>
</template>

<script>
import WorkspaceWrapper from "../../Common/WorkspaceWrapper";
import MiniRouletteWorkspace from "./MiniRouletteWorkspace.vue";

export default {
    props: {
        stateData: {
            type: Object,
            required: true,
            default() {
                return {
                    denom: null,
                    roundResult: {
                        bets: null,
                        resultNumber: null,
                    }
                }
            }
        }
    },
    components: {
        WorkspaceWrapper,
        MiniRouletteWorkspace
    }
}
</script>

<style scoped></style>
